
import Vue from "vue";
import { IMessage } from "./store/state";
import Message from "./components/Message.vue";

// Interfaces for component typechecking!
interface AppData {}

interface AppMethods {}

interface AppComputed {
  messages: IMessage[];
}

interface AppProps {}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
  name: "app",
  computed: {
    messages() {
      return this.$store.getters.messages;
    },
  },
  components: {
    Message,
  },
});

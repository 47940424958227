
import Vue from "vue";
import { IMessage } from "../store/state";

interface MessageData {
  isOpen: boolean;
}

interface MessageMethods {
  toggleMessage(): void;
}

interface MessageProps {
  message: IMessage;
}

interface MessageComputed {}

interface MessageProps {}

export default Vue.extend<
  MessageData,
  MessageMethods,
  MessageComputed,
  MessageProps
>({
  name: "app",
  data() {
    return {
      isOpen:
        !localStorage.getItem(`lul-important-message-${this.message.hash}`) ||
        localStorage.getItem(`lul-important-message-${this.message.hash}`) ===
          "is-open-true"
          ? true
          : false,
    };
  },
  methods: {
    toggleMessage() {
      console.log("messageHash: ", this.message.hash);
      this.isOpen = !this.isOpen;
      localStorage.setItem(
        `lul-important-message-${this.message.hash}`,
        `is-open-${this.isOpen}`
      );
    },
  },
  computed: {},
  components: {},
  props: {
    message: {},
  },
});

export interface IState {
  messages: IMessage[];
}

export interface IMessage {
  prio: number;
  title: string;
  descriptiveTitle: string;
  preamble: string;
  linkTextString: string;
  pageUri: string;
  expandText: string;
  isOpen: boolean;
  hash: string;
}

const deafultState: IState = {
  messages: [],
};

export default deafultState;
